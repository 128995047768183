import React from 'react';
import Link from 'next/link';
import s from './Footer.module.css';
import { FooterProps } from '@/types/components/footer';

const Footer = ({ footer }: FooterProps) => {
    function getYear() {
        return new Date().getFullYear();
    }

    return (
        <footer className={s.Footer}>
            {footer.company && (
                <span>
                    {getYear()} ©{' '}
                    <span
                        dangerouslySetInnerHTML={{ __html: footer.company }}
                    />
                </span>
            )}

            {footer.hyperlinks && (
                <nav>
                    <ul>
                        {footer.hyperlinks.map((footerlink, index) => (
                            <li key={index}>
                                <Link
                                    target={
                                        footerlink.value.hyperlinkLink.type ===
                                        'extern'
                                            ? '_blank'
                                            : undefined
                                    }
                                    href={footerlink.value.hyperlinkLink.value.toString()}>
                                    {footerlink.value.hyperlinkText}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
        </footer>
    );
};

export default Footer;
