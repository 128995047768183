import React from 'react';
import s from './Image.module.css';
import { EmbeddedImageProps } from '@/types/components/image';

const EmbeddedImage = ({ data, imageDetails }: EmbeddedImageProps) => {
    const imageElement = (
        <img
            src={`${imageDetails.src}`}
            alt={imageDetails.alt}
            className={s.image}
            width={imageDetails.width}
            height={imageDetails.height}
        />
    );

    return (
        <figure>
            {imageElement}
            {data && data.imageProperties.caption !== '' && (
                <figcaption className={s.figcaption}>
                    {data.imageProperties.caption}
                </figcaption>
            )}
        </figure>
    );
};

export default EmbeddedImage;
