import React from 'react';
import s from './RichTextBlock.module.css';
import RawHtml from '../../RawHtml/RawHtml';
import { RichTextBlockProps } from '@/types/components/blocks';

const RichTextBlock = ({ data }: { data: RichTextBlockProps }) => {
    const backgroundcolor = data.bgcolor?.backgroundColor || '';

    return (
        <div className={backgroundcolor}>
            <div className={s.RichTextBlock}>
                <div className="wb-container">
                    <div className={s.RichTextBlock__inner}>
                        <RawHtml html={data.richtext} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RichTextBlock;
